Number.prototype.currency = function(prefix) {
  var val = this.toString();

  return {
    cents : function () { return (val < 10 ? "0" : "") + val.substring(val.length - 2); },
    integer : function () { return val < 100 ? '0' : val.substring(0, val.length - 2); },
    toString : function () {
      prefix = prefix !== undefined ? prefix + (prefix === '' ? '' : ' ') : 'R$ ';
      return prefix + this.integer() + ',' + this.cents();
    }
  };
};
